<template>
  <div class="login-main">
    <nav-bar></nav-bar>
    <section class="fixed-box faqs-start-main box-flex">
      <div class="container review-main text-capitalize position-relative faq-main box-flex__wrapper">
        <div class="col-md-12 p-0">
          <div class="center-card">
            <div class="center-card-box | p-3 border-bottom">
              <div class="resturant-details">
                <h4 class="text-start">{{ $t('Choose Support Channel') }}</h4>
              </div>
            </div>
            <div class="center-card-box | p-3">
              <div class="spprt">
                <ul class="support-list">
                  <li class="support-list__item" v-if="phone">
                    <!-- <span>{{ $t('Call') }}:</span> -->
                    <a class="support-list__link" :href="'tel:' + phone" target="_blank"><span class="support-list__ic">
                        <img src="@/assets/images/call-ic.svg" alt="call"></span> {{ phone }}</a>
                  </li>
                  <li class="support-list__item" v-if="email">
                    <!-- <span>{{ $t('E-Mail') }}:</span> -->
                    <a class="support-list__link" :href="'mailto:' + email"><i class="far fa-envelope"></i> {{ email }}</a>
                  </li>
                  <li class="support-list__item" v-if="whatsapp">
                    <!-- <span>{{ $t('Whatsapp') }}:</span> -->
                    <a class="support-list__link" target="_blank"
                      :href="'https://wa.me/' + this.$auth.setting.country_code + whatsapp + '?text=Welcome to support'"><i
                        class="fab fa-whatsapp"></i> {{ whatsapp }}</a>
                  </li>
                  <li class="support-list__item"
                    v-if="$auth.user && $auth.setting && $auth.setting.client_chat_status == 'active' && isSupport">
                    <a class="support-list__link" href="/support-chat"> <i class="far fa-comments"></i> {{ $t('Chat')
                    }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from '../components/NavBar.vue'
import Footer from '../components/Footer.vue';

export default {
  components: { NavBar, Footer },
  data() {
    return {
      setting: null,
      email: null,
      phone: null,
      whatsapp: null,
      isSupport:false
    }
  },
  mounted() {
    setTimeout(() => {
      this.setting = this.$auth.setting;
      this.setSupportField();
    }, 1000);
  },
  methods: {
    setSupportField() {
      if (this.setting) {
        if (this.setting.support_field && this.setting.support_field.length > 0) {
          this.email = (this.setting.support_field[0].email) ? this.setting.support_field[0].email : null;
          this.phone = (this.setting.support_field[0].phone) ? this.setting.support_field[0].phone : null;
          this.whatsapp = (this.setting.support_field[0].whatsapp) ? this.setting.support_field[0].whatsapp : null;
        }
        this.isSupport = true;
        if (this.setting.support_field && !this.setting.support_field[0].phone && !this.setting.support_field[0].whatsapp && !this.setting.support_field[0].email) {
          this.$router.push({ name: "home" });
        }
      }
    }
  }
}
</script>

<style scoped>
.centertext {
  text-align: center;
  padding: 70px 0;
}</style>